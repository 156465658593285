import MuiTheme from '../theme';
import AuthRoute from './AuthRoute';
import SignRoute from './SignRoute';
import { connect } from "react-redux";
import { ThemeProvider } from '@mui/material/styles';
import NetworkError from '../shared/NetworkError';
import AuthService from "../services/auth.service";
import { ClimbingBoxLoader } from 'react-spinners';
import { fetchUserBegin } from "../actions/userActions";
import { AnimatePresence, motion } from 'framer-motion';
import { LeftSidebar, MinimalLayout } from '../blueprints';
import { Switch, Route, useLocation } from 'react-router-dom';
import React, { lazy, Suspense, Fragment, useEffect } from 'react';
import i18nf from '../i18next/i18next'
import { setError } from '../actions/errorActions';

const Home = lazy(() => import('../pages/home'));
const Role = lazy(() => import('../pages/Role'));
const User = lazy(() => import('../pages/User'));
const Login = lazy(() => import('../pages/Login'));
const Profile = lazy(() => import('../pages/Profile'));
const Themes = lazy(() => import('../pages/Themes'));
const Company = lazy(() => import('../pages/Company'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const Permission = lazy(() => import('../pages/Permission'));
const Signup = lazy(() => import('../pages/Signup/signup'));
const ChangePassword = lazy(() => import('../pages/ChangePassword'));
const CompanyModules = lazy(() => import("../pages/CompanyModules"));
const WarehouseOrder = lazy(() => import('../pages/WarehouseOrderPage'));
const ForgotPassword = lazy(() => import('../pages/Login/ForgotPassword'));
const ResetPassword = lazy(() => import('../pages/Login/ResetPassword'));
const CRM = lazy(() => import('../pages/CRM'));
const Product = lazy(() => import('../pages/Product'));
const ProductMaster = lazy(() => import('../pages/ProductMaster'));

const Routes = props => {
    const location = useLocation();
    const pageVariants = {
        initial: { opacity: 0, scale: 0.99 },
        in: { opacity: 1, scale: 1 },
        out: { opacity: 0, scale: 1.01 }
    };

    const pageTransition = {
        type: 'tween',
        ease: 'anticipate',
        duration: 0.4
    };

    useEffect(() => {
        // on refresh if user already logged in fetch user details from API
        if (localStorage.getItem("dt_user")) {
            const dt_user = JSON.parse(localStorage.getItem("dt_user"))
            AuthService.fetchUser(dt_user.company_id, dt_user.role_id).then(
                resp => {
                    props.fetchUserBegin(resp)
                },
                error => { props.setError({ error: 'ERR' }) }
            );
        }
    }, [props.user.language]);

    useEffect(() => {
        // light dark blood water forest
        if (props.user.theme) {
            const root = document.documentElement;
            document.body.id = props.user.theme
            localStorage.setItem("dt_theme", props.user.theme)
        } else if (localStorage.getItem("dt_theme")) {
            const root = document.documentElement;
            document.body.id = localStorage.getItem("dt_theme")
        }
    }, [props.user.theme])

    useEffect(() => {
        if (localStorage.getItem("dt_user")) {
            // language preference after login
            i18nf(props.user.language ? props.user.language : JSON.parse(localStorage.getItem("dt_user")).language)
        } else {
            // language preference before login
            i18nf(props.i18nextLng)
        }
    }, [props.user.language, props.i18nextLng])

    const SuspenseLoading = () => {
        return (
            <Fragment>
                <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
                    <div className="d-flex align-items-center flex-column px-4">
                        <ClimbingBoxLoader color={'#5383ff'} loading={true} />
                    </div>
                </div>
            </Fragment>
        );
    };

    return (
        <div>
            <ThemeProvider theme={MuiTheme(props.user.theme || (localStorage.getItem("dt_theme") || 'light'))}>
                <AnimatePresence>
                    <NetworkError>
                        <Switch>
                            <Route path={['/login', '/signup', '/reset-password', '/forgot-password']}>
                                <SignRoute>
                                    <MinimalLayout>
                                        <Switch location={location} key={location.pathname}>
                                            <Suspense fallback={<SuspenseLoading />}>
                                                <motion.div initial="initial" animate="in" exit="out" variants={pageVariants} transition={pageTransition}>
                                                    <Route path="/login" component={Login} />
                                                    <Route path="/signup" component={Signup} />
                                                    <Route path="/forgot-password" component={ForgotPassword} />
                                                    <Route path="/reset-password/:token" component={ResetPassword} />
                                                </motion.div>
                                            </Suspense>
                                        </Switch>
                                    </MinimalLayout>
                                </SignRoute>
                            </Route>

                            <AuthRoute>
                                <Switch location={location} key={location.pathname}>
                                    <LeftSidebar>
                                        <Suspense fallback={<SuspenseLoading />}>
                                            <motion.div initial="initial" animate="in" exit="out" variants={pageVariants} transition={pageTransition}>
                                                <Route path="/dashboard" component={Dashboard} />
                                                <Route path="/role" component={Role} />
                                                <Route path="/company" component={Company} />
                                                <Route path="/user" component={User} />
                                                <Route path="/permission" component={Permission} />
                                                <Route path="/change-password" component={ChangePassword} />
                                                <Route path="/profile" component={Profile} />
                                                <Route path="/themes" component={Themes} />
                                                <Route path="/crm" component={CRM} />
                                                <Route path="/warehouse-order" component={WarehouseOrder} />
                                                <Route path="/company-modules" component={CompanyModules} />
                                                <Route path="/product" component={Product} />
                                                <Route path="/product-master" component={ProductMaster} />
                                                <Route exact path="/" component={Dashboard} />
                                            </motion.div>
                                        </Suspense>
                                    </LeftSidebar>
                                </Switch>
                            </AuthRoute>
                        </Switch>
                    </NetworkError>
                </AnimatePresence>
            </ThemeProvider>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    fetchUserBegin: (user) => dispatch(fetchUserBegin(user)),
    setError: (err) => dispatch(setError(err))
});
const mapStateToProps = state => ({
    i18nextLng: state.common.i18nextLng,
    user: state.user.user,
    error: state.error.appError
});
export default connect(mapStateToProps, mapDispatchToProps)(Routes);
