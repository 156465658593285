import api from "./api";
import TokenService from "./token.service";

class AuthService {
    login(username, password) {
        return api.post("/api/com?service=auth&endpoint=api/login", { username, password })
            .then(response => {
                if (response.data.access_token) {
                    let user = response.data;
                    user.loggedIn = true;
                    return user;
                }
                return response.data;
            });
    }

    logout() {
        TokenService.removeUser();
        localStorage.removeItem('dt_mui_row_option');
    }

    getCurrentUser() {
        return TokenService.getUser();
    }

    // get user details along with permissions & sidebar menus
    fetchUser(company_id, role_id) {
        return api
            .get(`api/com?service=auth&endpoint=user/get/${company_id}/${role_id}`)
            .then((response) => {
                if (response.data.user.permissions) {
                    let user = JSON.parse(localStorage.getItem("dt_user"));
                    user.permissions = response.data.user.permissions
                    user.userCompanyList = response.data.user.userCompanyList
                    user.image = response.data.user.image
                    user.phone = response.data.user.phone
                    user.theme = response.data.user.theme
                    user.menu = response.data.user.menu
                    return user;
                }
                return response.data;
            });
    }

    getPermissions(company_id, role_id, refresh_token) {
        return api.get(`api/com?service=auth&endpoint=auth/permission/${company_id}/${role_id}/${refresh_token}`)
            .then(response => {
                return response.data;
            });
    }

    forgotPassword(username) {
        return api
            .post(`api/com?service=auth&endpoint=/auth/forgot-password`, {
                username
            })
            .then((response) => {
                return response.data.response;
            });
    }

    resetPassword(reset_token, password) {
        return api
            .post(`api/com?service=auth&endpoint=/auth/reset-password`, {
                reset_token,
                password
            })
            .then((response) => {
                return response.data.response;
            });
    }
}

export default new AuthService();