import axios from 'axios';
import api from '../services/api';

const dtUrl = process.env.REACT_APP_DT_API;
const baseUrl = process.env.REACT_APP_COMGATE_URL;

export async function login(url, data) {
    try {
        let headers = { "Content-Type": "application/json" };
        let resp = await axios.post(`${baseUrl}${url}`, data, headers);
        return resp.data;
    } catch(e) {
        return e;
    }
}
export async function postCall(url, data, isAuth = true) {
    try {
        let resp = await api.post(`${baseUrl}${url}`, data);
        return resp.data;
    } catch(error) {
        const err = error;
            if (err.response) {
                return err.response.data;
            }
            else {
                return error
            }
    }
}

export async function postCallForSignup(url, data, isAuth = true) {
    try {
        let headers = { "Content-Type": "application/json" };
        let endpoint = `${url}?service=auth&endpoint=${url}`;
        let resp = await axios.post(`${baseUrl}${endpoint}`,data ,{headers});
        return resp.data;
    } catch(error) {
        const err = error;
            if (err.response) {
                return err.response.data;
            }
            else {
                return error
            }
    }
}

export async function getCall(url) {
    try {
        let resp = await api.get(`${baseUrl}${url}`);
        return resp.data;
    } catch(e) {
        return e;
    }
}
export async function getToken() {
    let token = localStorage.getItem('refresh_token');
    let resp = await axios.post(`${baseUrl}api/refresh-token`, {'refresh_token':token});
    addSession(resp.data.access_token);
    localStorage.setItem('refresh_token', resp.data.refresh_token)
    return resp.data.access_token;
}

export function addSession(token) {
    localStorage.setItem("token", token);
    localStorage.setItem("tokenTime", new Date().getTime().toString());
}

export function isLogin() {
    return localStorage.getItem("token");
}

export function removeSession() {
    localStorage.removeItem("permission");
    localStorage.removeItem("token");
    localStorage.removeItem("tokenTime");
    localStorage.removeItem("refresh_token");
}

function calculateTokenTime() {
    let tokenTime = localStorage.getItem('tokenTime');
    if(!tokenTime) {
        return 10;
    }
    tokenTime = new Date(parseInt(tokenTime));
    let now = new Date();
    var diff =(tokenTime.getTime() - now.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
}
    export async function postFileApiCall(url, data, isAuth = true) {
        try {
            if(isAuth) {
                let token = localStorage.getItem('token');
                let time = calculateTokenTime();
                if(time > 5) {
                    token = await getToken();
                }
                let headers = {Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data'
                };
                    
                let resp = await axios.post(`${dtUrl}${url}`, data, {headers});
                return resp.data;
            }
           
        } catch(error) {
             const err = error;
            if (err.response) {
                return err.response.data;
            }
            else {
                return error
            }
        } 
}

export async function postCarrierApiCall(url, data, isAuth = true) {
    try {
        if(isAuth) {
            let token = localStorage.getItem('token');
            let time = calculateTokenTime();
            if(time > 5) {
                token = await getToken();
            }
            let headers = {Authorization: `Bearer ${token}`, 'Content-Type': 'application/json'};

            let resp = await axios.post(`${baseUrl}${url}`, data, {headers});
            return resp.data;
        }
    } catch(error) {
        const err = error;
        if (err.response) {
            return err.response.data;
        }
        else {
            return error
        }
    }
}

export async function getDropdownListApiCall(url, isAuth = true) {
    try {
        if(isAuth) {
            let token = localStorage.getItem('token');
            let time = calculateTokenTime();
            if(time > 5) {
                token = await getToken();
            }
            let headers = {Authorization: `Bearer ${token}`, 'Content-Type': 'application/json'};

            let resp = await axios.get(`${baseUrl}${url}`, {headers});
            return resp.data;
        }
    } catch(error) {
        const err = error;
        if (err.response) {
            return err.response.data;
        }
        else {
            return error
        }
    }
}

export async function getCarrierData(url, isAuth = true) {
    try {
        if(isAuth) {
            let token = localStorage.getItem('token');
            let time = calculateTokenTime();
            if(time > 5) {
                token = await getToken();
            }
            let headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin' : '*',
                'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            };

            let resp = await axios.get(`${baseUrl}${url}`, {headers});
            return resp.data;
        }
    } catch(error) {
        const err = error;
        if (err.response) {
            return err.response.data;
        }
        else {
            return error
        }
    }
}

export async function deleteCarrierData(url, id, isAuth = true) {
    try {
        if(isAuth) {
            let token = localStorage.getItem('token');
            let time = calculateTokenTime();
            if(time > 5) {
                token = await getToken();
            }
            let headers = {Authorization: `Bearer ${token}`, 'Content-Type': 'application/json'};

            if (Array.isArray(id) === true) {
                let resp = await axios.post(`${baseUrl}${url}delete-multiple` , id , {headers});
                return resp.data;
            }else {
                let resp = await axios.delete(`${baseUrl}${url}${id}/delete` , {headers});
                return resp.data;
            }
            
        }
    } catch(error) {
        const err = error;
        if (err.response) {
            return err.response.data;
        }
        else {
            return error
        }
    }
}

export async function sendFilterData(url, data, isAuth = true) {
    try {
        if(isAuth) {
            let token = localStorage.getItem('token');
            let time = calculateTokenTime();
            if(time > 5) {
                token = await getToken();
            }
            let headers = {Authorization: `Bearer ${token}`, 'Content-Type': 'application/json'};
            console.log("filter", data);
            let resp = await axios.post(`${baseUrl}${url}` , data , {headers});
            return resp.data;
        }
    } catch(error) {
        const err = error;
        if (err.response) {
            return err.response.data;
        }
        else {
            return error
        }
    }
}

export async function updateCarrierApiCall(url, data, id, isAuth = true) {
    try {
        if(isAuth) {
            let token = localStorage.getItem('token');
            let time = calculateTokenTime();
            if(time > 5) {
                token = await getToken();
            }
            let headers = {Authorization: `Bearer ${token}`, 'Content-Type': 'application/json'};

            let resp = await axios.put(`${baseUrl}${url}${id}`, data, {headers});
            return resp.data;
        }
    } catch(error) {
        const err = error;
        if (err.response) {
            return err.response.data;
        }
        else {
            return error
        }
    }
}

export async function getAllocatedPermissions(url, data, isAuth = true) {
    try {
        if(isAuth) {
            let token = localStorage.getItem('token');
            let time = calculateTokenTime();
            if(time > 5) {
                token = await getToken();
            }
            let headers = {Authorization: `Bearer ${token}`, 'Content-Type': 'application/json'};
            let resp = await axios.post(`${baseUrl}${url}`, data, {headers});
            return resp.data;
        }
    } catch(error) {
        const err = error;
        if (err.response) {
            return err.response.data;
        }
        else {
            return error
        }
    }
}