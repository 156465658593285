import axios from "axios";
import TokenService from "./token.service";

const instance = axios.create({
    baseURL: process.env.REACT_APP_COMGATE_URL
});

instance.interceptors.request.use(
    (config) => {
        const token = TokenService.getLocalAccessToken();
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        config.headers["lang"] = localStorage.getItem('dt_user') ? JSON.parse(localStorage.getItem('dt_user')).language : 'en';
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
instance.interceptors.response.use(
    res => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;
        if (err.code && err.code == 'ERR_NETWORK') {
            if (err.config.url != 'user/get') {
                // window.location.reload();
            }
        } else if (originalConfig.url !== "/auth/login" && err.response) {
            // Access Token was expired
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;

                try {
                    const rs = await instance.post("auth/refresh-token", {
                        refresh_token: TokenService.getLocalRefreshToken(),
                    });

                    const { access_token, refresh_token } = rs.data;
                    TokenService.updateLocalToken(access_token, refresh_token);

                    return instance(originalConfig);
                } catch (_error) {
                    TokenService.removeUser();
                    window.location.reload();
                    return Promise.reject(_error);
                }
            }
        }

        return Promise.reject(err);
    }
);
export default instance;