import CodeIcon from '@mui/icons-material/Code';
import ListAltIcon from '@mui/icons-material/ListAlt';
import BarChartIcon from '@mui/icons-material/BarChart';
import ChatIcon from '@mui/icons-material/ChatOutlined';
import MailIcon from '@mui/icons-material/MailOutlined';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import FolderIcon from '@mui/icons-material/FolderOutlined';
import GradeTwoTone from '@mui/icons-material/GradeTwoTone';
import PeopleIcon from '@mui/icons-material/PeopleOutlined';
import PersonIcon from '@mui/icons-material/PersonOutlined';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ReceiptIcon from '@mui/icons-material/ReceiptOutlined';
import LockOpenIcon from '@mui/icons-material/LockOpenOutlined';
import PresentToAllIcon from '@mui/icons-material/PresentToAll';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DashboardIcon from '@mui/icons-material/DashboardOutlined';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import SecurityIcon from '@mui/icons-material/Security';
import ApprovalIcon from '@mui/icons-material/Approval';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import CategoryIcon from '@mui/icons-material/Category';
import InventoryIcon from '@mui/icons-material/Inventory';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import EditOffIcon from '@mui/icons-material/EditOff';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import AssessmentIcon from '@mui/icons-material/Assessment';
import DatasetIcon from '@mui/icons-material/Dataset';

var iconsMap = {
    ChatIcon: ChatIcon,
    CodeIcon: CodeIcon,
    MailIcon: MailIcon,
    HomeIcon: HomeIcon,
    ErrorIcon: ErrorIcon,
    FolderIcon: FolderIcon,
    PeopleIcon: PeopleIcon,
    PersonIcon: PersonIcon,
    ListAltIcon: ListAltIcon,
    ReceiptIcon: ReceiptIcon,
    BarChartIcon: BarChartIcon,
    LockOpenIcon: LockOpenIcon,
    GradeTwoTone: GradeTwoTone,
    SettingsIcon: SettingsIcon,
    DashboardIcon: DashboardIcon,
    ViewModuleIcon: ViewModuleIcon,
    PresentToAllIcon: PresentToAllIcon,
    CalendarTodayIcon: CalendarTodayIcon,
    DashboardTwoToneIcon: DashboardTwoToneIcon,
    WarehouseIcon: WarehouseIcon,
    SecurityIcon: SecurityIcon,
    ApprovalIcon: ApprovalIcon,
    GroupAddIcon: GroupAddIcon,
    ManageAccountsIcon: ManageAccountsIcon,
    CategoryIcon: CategoryIcon,
    InventoryIcon: InventoryIcon,
    ShoppingCartIcon: ShoppingCartIcon,
    TrackChangesIcon: TrackChangesIcon,
    EditOffIcon: EditOffIcon,
    Diversity3Icon: Diversity3Icon,
    AssessmentIcon: AssessmentIcon,
    DatasetIcon: DatasetIcon
};
function navItems({ menu }) {
    return [{
        content: JSON.parse(JSON.stringify(menu || []),
            (key, value) => {
                if (key === 'icon') {
                    return iconsMap[value];
                } else {
                    return value;
                }
            }
        )
    }]
}
export default navItems;